import { get, post } from 'Server';
import { urls } from 'Reducers/monetizers';
import { isEmpty } from 'Helpers/objects';
import { paramsToQueryString } from 'Helpers/url';
import {
    isFetchingUserOrders,
    userOrdersSelector,
    isFetchingBillingInfo,
    billingInfoSelector,
    userInvoicesSelector,
    isFetchingUserInvoices,
    getRenewalPackagesList,
    isFetchingRenewalPackagesList
} from 'Selectors/monetizers';
import {
    getLoggedUser
} from 'Selectors/user';
import { INVOICES_LIMIT } from 'Constants/monetizers';
import Auth from '../Auth';

export const getUserOrders = () => (dispatch, getState) => {
    const state = getState();
    const user = getLoggedUser(state);
    const orders = userOrdersSelector(state);

    if (!isEmpty(orders) || isFetchingUserOrders(state, user.id)) {
        return Promise.resolve();
    }

    return dispatch(get(urls.userPackages(user.id), 'MONETIZERS', {}, { 'X-Mudra-Platform': 'PWA' }));
};

export const getUserInvoices = offset => (dispatch, getState) => {
    const state = getState();
    const invoices = userInvoicesSelector(state)(offset);

    if (!isEmpty(invoices) || isFetchingUserInvoices(state)) {
        return Promise.resolve();
    }

    const params = {
        offset,
        limit: INVOICES_LIMIT
    };

    return dispatch(get(urls.orders, 'MONETIZERS', params));
};

export const getBillingInfo = (force = false, queryParams) => (dispatch, getState) => {
    const state = getState();
    const billingInfo = billingInfoSelector(state, queryParams);

    if (!force && (!isEmpty(billingInfo) || isFetchingBillingInfo(state))) {
        return Promise.resolve();
    }

    return dispatch(get(urls.billing, 'MONETIZERS', queryParams));
};

export const verifyGstDetails = gstin => dispatch => {
    return dispatch(get(urls.getGSTDetails, 'MONETIZERS', {
        gstin
    }));
};

export const saveGstin = gstin => ({
    type: 'SAVE_GSTIN',
    data: gstin
});

export const saveBillingInfo = (values, queryParams) => dispatch => {
    const saveUrl = queryParams ? `${urls.saveBilling}?${paramsToQueryString(queryParams)}` : urls.saveBilling;

    return dispatch(post(saveUrl, 'MONETIZERS', {
        data: values
    }));
};

export const getUserRenewalPackages = (force = false) => (dispatch, getState) => {
    const state = getState();

    const renewPackage = getRenewalPackagesList(state);

    if (!force && (!isEmpty(renewPackage) || isFetchingRenewalPackagesList(state))) {
        return Promise.resolve();
    }

    const userId = Auth?.getUser()?.id;

    return dispatch(get(`${urls.renewPackages}?renewable=true`, 'MONETIZERS', { }, { 'X-User-Id': userId }));
};

export const getCouponAgainstPackage = data => dispatch => {
    return dispatch(post(`${urls.getCouponAgainstPackage}`, 'MONETIZERS',
        data));
};
